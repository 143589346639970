/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { CompanyEmployeeFromJSON, CompanyEmployeeToJSON, } from './CompanyEmployee';
/**
 * Check if a given object implements the Employee interface.
 */
export function instanceOfEmployee(value) {
    if (!('email' in value))
        return false;
    if (!('fullName' in value))
        return false;
    if (!('createdAt' in value))
        return false;
    if (!('updatedAt' in value))
        return false;
    return true;
}
export function EmployeeFromJSON(json) {
    return EmployeeFromJSONTyped(json, false);
}
export function EmployeeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'gistUserId': json['gistUserId'] == null ? undefined : json['gistUserId'],
        'fullName': json['fullName'],
        'title': json['title'] == null ? undefined : json['title'],
        'reportsTo': json['reportsTo'] == null ? undefined : EmployeeFromJSON(json['reportsTo']),
        'directReports': json['directReports'] == null ? undefined : (json['directReports'].map(EmployeeFromJSON)),
        'avatarUrl': json['avatarUrl'] == null ? undefined : json['avatarUrl'],
        'companyEmployees': json['companyEmployees'] == null ? undefined : (json['companyEmployees'].map(CompanyEmployeeFromJSON)),
        'ownedCompanies': json['ownedCompanies'] == null ? undefined : (json['ownedCompanies'].map(CompanyFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function EmployeeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'phone': value['phone'],
        'gistUserId': value['gistUserId'],
        'fullName': value['fullName'],
        'title': value['title'],
        'reportsTo': EmployeeToJSON(value['reportsTo']),
        'directReports': value['directReports'] == null ? undefined : (value['directReports'].map(EmployeeToJSON)),
        'avatarUrl': value['avatarUrl'],
        'companyEmployees': value['companyEmployees'] == null ? undefined : (value['companyEmployees'].map(CompanyEmployeeToJSON)),
        'ownedCompanies': value['ownedCompanies'] == null ? undefined : (value['ownedCompanies'].map(CompanyToJSON)),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}
