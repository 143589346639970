/* tslint:disable */
/* eslint-disable */
/**
 * Game Plan
 * The API description of the almighty Game Plan.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyFromJSON, CompanyToJSON, } from './Company';
import { LinkFromJSON, LinkToJSON, } from './Link';
import { PieChartDtoFromJSON, PieChartDtoToJSON, } from './PieChartDto';
import { SlimEmployeeWithRoleDtoFromJSON, SlimEmployeeWithRoleDtoToJSON, } from './SlimEmployeeWithRoleDto';
import { SlimKpiWithYearStatusDtoFromJSON, SlimKpiWithYearStatusDtoToJSON, } from './SlimKpiWithYearStatusDto';
/**
 * Check if a given object implements the GetCompanyPageResponseDto interface.
 */
export function instanceOfGetCompanyPageResponseDto(value) {
    if (!('company' in value))
        return false;
    if (!('leadership' in value))
        return false;
    if (!('team' in value))
        return false;
    if (!('goals' in value))
        return false;
    if (!('teamBreakdown' in value))
        return false;
    if (!('actionsByCategory' in value))
        return false;
    if (!('actionsByFocusArea' in value))
        return false;
    if (!('kpisByGroupType' in value))
        return false;
    if (!('kpis' in value))
        return false;
    if (!('links' in value))
        return false;
    return true;
}
export function GetCompanyPageResponseDtoFromJSON(json) {
    return GetCompanyPageResponseDtoFromJSONTyped(json, false);
}
export function GetCompanyPageResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': CompanyFromJSON(json['company']),
        'leadership': (json['leadership'].map(SlimEmployeeWithRoleDtoFromJSON)),
        'team': (json['team'].map(SlimEmployeeWithRoleDtoFromJSON)),
        'goals': json['goals'],
        'teamBreakdown': PieChartDtoFromJSON(json['teamBreakdown']),
        'actionsByCategory': PieChartDtoFromJSON(json['actionsByCategory']),
        'actionsByFocusArea': PieChartDtoFromJSON(json['actionsByFocusArea']),
        'kpisByGroupType': PieChartDtoFromJSON(json['kpisByGroupType']),
        'kpis': (json['kpis'].map(SlimKpiWithYearStatusDtoFromJSON)),
        'links': (json['links'].map(LinkFromJSON)),
    };
}
export function GetCompanyPageResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'company': CompanyToJSON(value['company']),
        'leadership': (value['leadership'].map(SlimEmployeeWithRoleDtoToJSON)),
        'team': (value['team'].map(SlimEmployeeWithRoleDtoToJSON)),
        'goals': value['goals'],
        'teamBreakdown': PieChartDtoToJSON(value['teamBreakdown']),
        'actionsByCategory': PieChartDtoToJSON(value['actionsByCategory']),
        'actionsByFocusArea': PieChartDtoToJSON(value['actionsByFocusArea']),
        'kpisByGroupType': PieChartDtoToJSON(value['kpisByGroupType']),
        'kpis': (value['kpis'].map(SlimKpiWithYearStatusDtoToJSON)),
        'links': (value['links'].map(LinkToJSON)),
    };
}
